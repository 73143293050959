<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <v-img :src="require('@/assets/images/forward-logo.png')" :max-width="500"></v-img>
          <!--  <router-link class="d-flex align-center">
            <h2 class="text-2xl font-weight-semibold">Forward Professional and Creative Limited</h2>
          </router-link> -->
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="username"
              outlined
              label="帳戶"
              hide-details
              class="mb-3"
              v-on:keyup.enter="Login"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="密碼"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              v-on:keyup.enter="Login"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <!-- <v-text-field
              v-model="email"
              outlined
              label="Email"
              hide-details
              class="mb-3"
              :rules="[value => !!value || 'Required.']"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[value => !!value || 'Required.']"
            ></v-text-field> -->

            <!--             <router-link to="/">
              <v-btn block color="primary" class="mt-6"> Login </v-btn>
            </router-link> -->
            <v-btn block color="primary" class="mt-6" @click="Login"> 登入 </v-btn>

            <!-- <v-btn block color="primary" class="mt-6" @click="Login"> Login </v-btn> -->
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-alert style="position: fixed; top: 0" type="error" class="mt-5" :value="alert" transition="slide-y-transition">
      用戶名稱或密碼錯誤
    </v-alert>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    isPasswordVisible: false,
    alert: false,
    username: '',
    password: '',
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
  }),
  methods: {
    ...mapActions(['getData']),
    async Login() {
      if (!this.alert) {
        this.alert = true

        setTimeout(() => {
          this.alert = false
        }, 2000)
      }
      // if (this.username == '' || this.password == '') {
      //   return
      // }
      // try {
      //   //await commit('setUser', User.get('username'))
      //   let params = new URLSearchParams()
      //   params.set(
      //     'cms_login',
      //     JSON.stringify({
      //       user: this.email,
      //       password: this.password,
      //     }),
      //   )
      //   //let result = await getData(params)
      //   let send_data = []
      //   send_data['params'] = params
      //   send_data['method'] = 'getLoginData'
      //   this.getData(send_data)
      // } catch (error) {
      //   console.log(error)
      // }
    },
  },
  computed: mapGetters(['login_status', 'login_data']),
  watch: {
    // login_status: function (newVal, oldval) {
    //   console.log(newVal)
    //   // if(newVal){
    //   //   this.$router.push({ name: 'Members' })
    //   // }
    // },
    login_data: function (newVal, oldval) {
      // console.log(newVal)
      // if (newVal == '') {
      //   document.getElementById('error').classList.remove('hide')
      //   document.getElementById('error').classList.add('d-flex')
      // } else {
      //   document.getElementById('error').classList.add('hide')
      //   document.getElementById('error').classList.remove('d-flex')
      //   this.$router.push({ name: 'Members' })
      // }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
